import TRTC from 'trtc-js-sdk';

export function client(userId, sdkAppId, userSig) {

      const client = TRTC.createClient({
            mode: 'rtc',
            sdkAppId,
            userId,
            userSig,
      });

      let remoteStreamList = [];

      return {
            startListen(cameraEl, screenEl, audioEl) {

                  client.on('stream-added', (event) => {
                        const remoteStream = event.stream
                        const remoteUserId = remoteStream.getUserId();
                        if (userId === remoteUserId) { // 取消来自自身的音频流
                              client.unsubscribe(remoteStream);
                        } else {
                              client.subscribe(remoteStream);
                              remoteStreamList.push(remoteStream)
                        }

                        remoteStream.on('player-state-changed', (event) => {
                              switch (event.state) {
                                    case 'PLAYING':
                                          this.onLiving && this.onLiving();
                                          break;
                              }
                        })
                        client.subscribe(remoteStream);
                  });

                  client.on('stream-subscribed', (event) => {

                        const remoteStream = event.stream;
                        let el;
                        if (remoteStream.getUserId().startsWith('screen-')) {
                              el = screenEl;
                        } else if (remoteStream.getUserId().startsWith('teacher-')) {
                              el = cameraEl;
                        } else {
                              el = audioEl;
                        }

                        const play = () => {
                              remoteStream.play(el).then(() => {
                                    if (remoteStream.getUserId().startsWith('teacher-')) {
                                          this.onLiving && this.onLiving();
                                    } else if (remoteStream.getUserId().startsWith('screen-')) {
                                          this.onScreening && this.onScreening();
                                    }

                              }).catch((error) => {
                                    const errorCode = error.getCode();
                                    if (errorCode === 0x4042) {
                                          remoteStream.close();
                                          const resume = () => {
                                                document.removeEventListener('click', resume);
                                                play();
                                          }
                                          document.addEventListener('click', resume);
                                          alert('没有听到声音点击一下页面, 再试一试');
                                    }
                              })
                        };
                        play();
                  });

                  client.on('stream-removed', (event) => {
                        const remoteStream = event.stream;
                        if (remoteStream.getUserId().startsWith('screen-')) {
                              this.onScreenFinish && this.onScreenFinish();
                        }
                        remoteStream.stop();
                        remoteStream.close();
                  }),


                  client.on('mute-video', () => {
                        this.onVideoClose && this.onVideoClose();

                  });

                  client.on('unmute-video', () => {
                        this.onVideoOpen && this.onVideoOpen();
                  });
            },

            stopLiving() {
                  for (let stream of remoteStreamList) {
                        const audioTrack = stream.getAudioTrack();
                        if (audioTrack) {
                              audioTrack.stop();
                        }
                        stream.stop();
                        stream.close();
                  }
                  remoteStreamList = [];
            },

            joinRoom(roomId) {
                  roomId = parseInt(roomId);
                  client.join({ roomId }).then(() => {
                        this.onJoined && this.onJoined();
                  }).catch((error) => {
                        console.log('进房失败 ', error);
                  });
            },
            leaveRoom() {
                  this.stopLiving();
                  client.leave().then(() => {
                        this.onLeaveRoom && this.onLeaveRoom();
                  }).catch(error => {
                        console.error('leaving room failed: ' + error);
                  });
            },

            switchAnchor() {
                  const localStream = TRTC.createStream({ userId, audio: true, video: false });
                  localStream.initialize().then(() => {
                        console.log('初始化本地流成功');
                        client.publish(localStream).then(() => {
                              console.log('本地流发布成功');
                        }).catch((error) => {
                              console.error('本地流发布失败 ' + error);
                        });
                  }).catch((error) => {
                        console.error('初始化本地流失败 ' + error);
                  });
                  return function() {
                        client.unpublish(localStream).then(() => {
                              localStream.close();
                              console.log('本地流取消成功');
                        }).catch((error) => {
                              console.error('本地流取消失败 ' + error);
                        });
                  }
            }
      }
}